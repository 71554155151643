import { relations } from 'drizzle-orm'
import { unique } from 'drizzle-orm/pg-core'
import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Contracts } from './contracts'
import { Organizations } from './organizations'
import { timestamps } from './shared'
import { generateId } from './utils'

export const Departments = pgTable(
  'departments',

  (t) => ({
    id: t
      .text()
      .primaryKey()
      .$defaultFn(() => generateId(12)),
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    name: t.text().notNull(),
    ...timestamps
  }),
  (t) => [unique('departments_org_id_name_unique').on(t.orgId, t.name)]
)

export type Department = typeof Departments.$inferSelect
export type DepartmentInsert = typeof Departments.$inferInsert

export const departmentRelations = relations(Departments, ({ one, many }) => ({
  contracts: many(Contracts),
  organization: one(Organizations, {
    fields: [Departments.orgId],
    references: [Organizations.id]
  })
}))

export const insertDepartmentSchema = createInsertSchema(Departments, {
  name: (schema) => schema.trim().min(1)
})
export const selectDepartmentSchema = createSelectSchema(Departments)
