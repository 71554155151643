import { customType } from 'drizzle-orm/pg-core'
import type { z } from 'zod'

export const customJsonb = <T extends z.ZodTypeAny>(
  name: string,
  schema: T
) => {
  return customType<{ data: z.infer<T>; driverData: string }>({
    dataType() {
      return 'jsonb'
    },
    toDriver(value: z.infer<T>): string {
      return JSON.stringify(schema.parse(value))
    },
    fromDriver(value: unknown) {
      return schema.parse(value) as z.infer<T>
    }
  })(name)
}

export const customEnum = <K extends string, T extends z.ZodEnum<[K, ...K[]]>>(
  schema: T
) => {
  return customType<{ data: z.infer<T>; driverData: string }>({
    dataType() {
      return 'text'
    },
    toDriver(value: z.infer<T>): string {
      const safeValue = schema.parse(value)
      return safeValue
    },
    fromDriver(value: string) {
      return schema.parse(value) as z.infer<T>
    }
  })()
}
