import { primaryKey } from 'drizzle-orm/pg-core'
import { customEnum } from './custom-types'
import { AuthProvidersEnum } from './enums'
import { authSchema } from './schemas'
import { timestamps } from './shared'
import { Users } from './users'

export const AuthProviders = authSchema.table(
  'providers',
  (t) => ({
    provider: customEnum(AuthProvidersEnum).notNull(),
    providerId: t.text().notNull(),
    userId: t
      .text()
      .notNull()
      .references(() => Users.id, { onDelete: 'cascade' }),
    createdAt: timestamps.createdAt
  }),
  (table) => [primaryKey({ columns: [table.provider, table.providerId] })]
)

export type AuthProvider = typeof AuthProviders.$inferSelect
export type AuthProviderInsert = typeof AuthProviders.$inferInsert
