import { relations } from 'drizzle-orm'
import { pgTable, unique } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'
import { customEnum } from './custom-types'
import { ReminderMilestoneEnum } from './enums'
import { Organizations } from './organizations'
import { timestamps } from './shared'
import { generateId } from './utils'

export const Reminders = pgTable(
  'reminders',
  (t) => ({
    id: t
      .text()
      .primaryKey()
      .$defaultFn(() => generateId(12)),
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    milestone: customEnum(ReminderMilestoneEnum).notNull(),
    daysNotice: t.integer().notNull(),
    ...timestamps
  }),
  (table) => [
    unique('reminders_org_id_days_notice_milestone_unique').on(
      table.orgId,
      table.daysNotice,
      table.milestone
    )
  ]
)

export type Reminder = typeof Reminders.$inferSelect
export type ReminderInsert = typeof Reminders.$inferInsert

export const reminderRelations = relations(Reminders, ({ one }) => ({
  organization: one(Organizations, {
    fields: [Reminders.orgId],
    references: [Organizations.id]
  })
}))

export const insertReminderSchema = createInsertSchema(Reminders, {
  daysNotice: z.coerce.number().int().min(1).max(180)
})

export const selectReminderSchema = createSelectSchema(Reminders)
