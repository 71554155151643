import { relations } from 'drizzle-orm'
import { pgTable, primaryKey } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Organizations } from './organizations'
import { Roles } from './roles'
import { timestamps } from './shared'
import { UserDepartments } from './user-departments'
import { Users } from './users'

export const Memberships = pgTable(
  'memberships',
  (t) => ({
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    userId: t
      .text()
      .notNull()
      .references(() => Users.id, { onDelete: 'cascade' }),
    roleId: t
      .integer()
      .notNull()
      .references(() => Roles.id, {
        onDelete: 'restrict'
      }),
    createdAt: timestamps.createdAt
  }),
  (t) => {
    return [primaryKey({ columns: [t.orgId, t.userId] })]
  }
)

export type Membership = typeof Memberships.$inferSelect
export type MembershipInsert = typeof Memberships.$inferInsert

export const membershipRelations = relations(Memberships, ({ one, many }) => ({
  organization: one(Organizations, {
    fields: [Memberships.orgId],
    references: [Organizations.id]
  }),
  role: one(Roles, {
    fields: [Memberships.roleId],
    references: [Roles.id]
  }),
  user: one(Users, {
    fields: [Memberships.userId],
    references: [Users.id]
  }),
  userDepartments: many(UserDepartments)
}))

export const insertMembershipSchema = createInsertSchema(Memberships)
export const selectMembershipSchema = createSelectSchema(Memberships)
