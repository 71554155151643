import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Contracts } from './contracts'
import { timestamps } from './shared'
import { generateId } from './utils'

export const Attachments = pgTable('attachments', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  contractId: t
    .text()
    .notNull()
    .references(() => Contracts.id, { onDelete: 'cascade' }),
  displayName: t.text().notNull(),
  filename: t.text().notNull(),
  deletedAt: t.timestamp({
    mode: 'date',
    withTimezone: true
  }),
  ...timestamps
}))

export type Attachment = typeof Attachments.$inferSelect
export type AttachmentInsert = typeof Attachments.$inferInsert

export const attachmentRelations = relations(Attachments, ({ one }) => ({
  contract: one(Contracts, {
    fields: [Attachments.contractId],
    references: [Contracts.id]
  })
}))

const validFilenameRegex = /^[^\x7F-\x84\x86-\x9F#\[\]\*\?:"<>|]+$/

export const insertAttachmentSchema = createInsertSchema(Attachments, {
  displayName: (schema) =>
    schema
      .trim()
      .min(1, 'This field is required.')
      .regex(validFilenameRegex, 'This is not a valid filename.')
})
export const selectAttachmentSchema = createSelectSchema(Attachments)
