import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Contracts } from './contracts'
import { timestamps } from './shared'
import { generateId } from './utils'

export const Vendors = pgTable('vendors', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(8)),
  name: t.text().notNull(),
  tagline: t.text().notNull(),
  iconUrl: t.text().notNull(),
  website: t.text().notNull(),
  ...timestamps
}))

export type Vendor = typeof Vendors.$inferSelect
export type VendorInsert = typeof Vendors.$inferInsert

export const vendorRelations = relations(Vendors, ({ many }) => ({
  contracts: many(Contracts)
}))

export const insertVendorSchema = createInsertSchema(Vendors, {
  iconUrl: (schema) => schema.url(`Please enter a valid URL.`),
  website: (schema) => schema.url(`Please enter a valid URL.`),
  name: (schema) => schema.trim().min(1),
  tagline: (schema) => schema.trim().min(1)
})
export const selectVendorSchema = createSelectSchema(Vendors, {
  iconUrl: (schema) => schema.url(),
  website: (schema) => schema.url()
})
