import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { AuthInvitations } from './auth-invitations'
import { Contracts } from './contracts'
import { Departments } from './departments'
import { Memberships } from './memberships'
import { Reminders } from './reminders'
import { timestamps } from './shared'
import { generateId } from './utils'

export const Organizations = pgTable('organizations', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  name: t.text().notNull(),
  ...timestamps
}))

export type Organization = typeof Organizations.$inferSelect
export type OrganizationInsert = typeof Organizations.$inferInsert

export const OrganizationRelations = relations(Organizations, ({ many }) => ({
  contracts: many(Contracts),
  departments: many(Departments),
  invitations: many(AuthInvitations),
  memberships: many(Memberships),
  reminders: many(Reminders)
}))

export const insertOrganizationSchema = createInsertSchema(Organizations)
export const selectOrganizationSchema = createSelectSchema(Organizations)
