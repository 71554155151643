import { relations } from 'drizzle-orm'
import { pgTable, unique } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Contracts } from './contracts'
import { Organizations } from './organizations'
import { Reminders } from './reminders'
import { timestamps } from './shared'

export const SentReminders = pgTable(
  'sent_reminders',
  (t) => ({
    id: t.integer().primaryKey().generatedAlwaysAsIdentity(),
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    reminderId: t.text().references(() => Reminders.id, {
      onDelete: 'set null'
    }),
    contractId: t.text().references(() => Contracts.id, {
      onDelete: 'set null'
    }),
    sentOn: t.date({ mode: 'date' }).notNull(),
    sentTo: t.text(),
    createdAt: timestamps.createdAt
  }),
  (t) => [
    unique('sent_reminders_reminder_id_contract_id_sent_on_unique').on(
      t.reminderId,
      t.contractId,
      t.sentOn
    )
  ]
)

export type SentReminder = typeof SentReminders.$inferSelect
export type SentReminderInsert = typeof SentReminders.$inferInsert

export const sentReminderRelations = relations(SentReminders, ({ one }) => ({
  contract: one(Contracts, {
    fields: [SentReminders.contractId],
    references: [Contracts.id]
  }),
  organization: one(Organizations, {
    fields: [SentReminders.orgId],
    references: [Organizations.id]
  }),
  reminder: one(Reminders, {
    fields: [SentReminders.reminderId],
    references: [Reminders.id]
  })
}))

export const insertSentReminderSchema = createInsertSchema(SentReminders)
export const selectSentReminderSchema = createSelectSchema(SentReminders)
