import { type SQL, relations, sql } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Memberships } from './memberships'
import { timestamps } from './shared'
import { UserDepartments } from './user-departments'
import { generateId } from './utils'

export const Users = pgTable('users', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  email: t.text().notNull().unique(),
  firstName: t.text().notNull(),
  lastName: t.text(),
  displayName: t
    .text()
    .notNull()
    .generatedAlwaysAs(
      (): SQL =>
        sql`${Users.firstName} || ' ' || COALESCE(SUBSTRING(${Users.lastName}, 1, 1), '')`
    ),
  fullName: t
    .text()
    .notNull()
    .generatedAlwaysAs(
      (): SQL => sql`${Users.firstName} || ' ' || ${Users.lastName}`
    ),
  avatarUrl: t.text(),
  lastSignInAt: t.timestamp({
    mode: 'date',
    withTimezone: true
  }),
  isInternal: t
    .boolean()
    .notNull()
    .generatedAlwaysAs(
      (): SQL =>
        sql`${Users.email} LIKE '%@tech-source.co' OR ${Users.email} LIKE '%@hapstack.com'`
    ),
  ...timestamps
}))

export type User = typeof Users.$inferSelect
export type UserInsert = typeof Users.$inferInsert

export const userRelations = relations(Users, ({ many }) => ({
  departments: many(UserDepartments),
  memberships: many(Memberships)
}))

export const insertUserSchema = createInsertSchema(Users, {
  email: (schema) => schema.email('Please enter a valid email address.')
})
export const selectUserSchema = createSelectSchema(Users)
