import { relations } from 'drizzle-orm'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { authSchema } from './schemas'
import { timestamps } from './shared'
import { Users } from './users'
import { generateId } from './utils'

export const AuthSessions = authSchema.table('sessions', (t) => ({
  id: t
    .text()
    .primaryKey()
    .notNull()
    .$defaultFn(() => generateId()),
  userId: t
    .text()
    .notNull()
    .unique('sessions_user_id_unique')
    .references(() => Users.id, { onDelete: 'cascade' }),
  expiresAt: t
    .timestamp({
      mode: 'date',
      withTimezone: true
    })
    .notNull(),
  createdAt: timestamps.createdAt
}))

export type AuthSession = typeof AuthSessions.$inferSelect
export type AuthSessionInsert = typeof AuthSessions.$inferInsert

export const authSessionRelations = relations(AuthSessions, ({ one }) => ({
  user: one(Users, {
    fields: [AuthSessions.userId],
    references: [Users.id]
  })
}))

export const insertAuthSessionSchema = createInsertSchema(AuthSessions)
export const selectAuthSessionSchema = createSelectSchema(AuthSessions)
