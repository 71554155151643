import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { customEnum } from './custom-types'
import { UserRoleEnum } from './enums'

export const Roles = pgTable('roles', (t) => ({
  id: t.integer().primaryKey().generatedAlwaysAsIdentity(),
  role: customEnum(UserRoleEnum).notNull().unique()
}))

export type Role = typeof Roles.$inferSelect
export type UserRole = Role['role']
export type RoleInsert = typeof Roles.$inferInsert

export const insertRoleSchema = createInsertSchema(Roles)
export const selectRoleSchema = createSelectSchema(Roles)

export const UserRoleCopy: Record<UserRole, string> = {
  admin: 'Admin',
  restricted: 'Restricted',
  viewer: 'Viewer'
}
