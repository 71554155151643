import { relations } from 'drizzle-orm'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Organizations } from './organizations'
import { Roles } from './roles'
import { authSchema } from './schemas'
import { timestamps } from './shared'
import { generateId } from './utils'

export const AuthInvitations = authSchema.table('invitations', (t) => ({
  id: t
    .text()
    .primaryKey()
    .notNull()
    .$defaultFn(() => generateId()),
  roleId: t
    .integer()
    .notNull()
    .references(() => Roles.id, { onDelete: 'restrict' }),
  orgId: t
    .text()
    .notNull()
    .references(() => Organizations.id, { onDelete: 'cascade' }),
  createdAt: timestamps.createdAt
}))

export type AuthInvitation = typeof AuthInvitations.$inferSelect
export type AuthInvitationInsert = typeof AuthInvitations.$inferInsert

export const authInvitationRelations = relations(
  AuthInvitations,
  ({ one }) => ({
    role: one(Roles, {
      fields: [AuthInvitations.roleId],
      references: [Roles.id]
    }),
    organization: one(Organizations, {
      fields: [AuthInvitations.orgId],
      references: [Organizations.id]
    })
  })
)

export const insertInvitationSchema = createInsertSchema(AuthInvitations)
export const selectInvitationSchema = createSelectSchema(AuthInvitations)
