import { type SQL, relations, sql } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'
import { Attachments } from './attachments'
import { customEnum } from './custom-types'
import { Departments } from './departments'
import { ContractStatusEnum, ContractTypeEnum, PaymentTermsEnum } from './enums'
import { Organizations } from './organizations'
import { timestamps } from './shared'
import { Users } from './users'
import { generateId } from './utils'
import { Vendors } from './vendors'

export const Contracts = pgTable('contracts', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  orgId: t
    .text()
    .notNull()
    .references(() => Organizations.id, { onDelete: 'cascade' }),
  vendorId: t
    .text()
    .notNull()
    .references(() => Vendors.id, { onDelete: 'cascade' }),
  ownerId: t.text().references(() => Users.id, {
    onDelete: 'set null'
  }),
  departmentId: t.text().references(() => Departments.id, {
    onDelete: 'set null'
  }),
  name: t.text(),
  type: ContractTypeEnum().notNull().default('contract'),
  termStart: t.date({ mode: 'date' }),
  termEnd: t.date({ mode: 'date' }),
  termLengthInDays: t
    .integer()
    .generatedAlwaysAs(
      (): SQL => sql`${Contracts.termEnd}::date - ${Contracts.termStart}::date`
    ),
  autorenews: t.boolean().notNull().default(true),
  status: customEnum(ContractStatusEnum).notNull().default('active'),
  paymentTerms: PaymentTermsEnum(),
  generalNotes: t.text(),
  value: t.bigint({ mode: 'number' }).notNull().default(0),
  valueDollars: t
    .bigint({ mode: 'number' })
    .generatedAlwaysAs((): SQL => sql`ROUND(${Contracts.value} / 100, 0)`),
  annualizedValueDollars: t.bigint({ mode: 'number' }).generatedAlwaysAs(
    (): SQL => sql`
        CASE
          WHEN ${Contracts.type} = 'recurring' THEN ROUND((${Contracts.value} * 12 / 100)::numeric, 0)
          WHEN ${Contracts.type} = 'contract' AND (${Contracts.termEnd} - ${Contracts.termStart}) > 0 THEN ROUND((${Contracts.value} / (${Contracts.termEnd} - ${Contracts.termStart}))::numeric * 365.25 / 100::numeric, 0)
          ELSE NULL::numeric
        END
      `
  ),
  noticePeriodInDays: t.integer(),
  noticeDeadline: t
    .date({ mode: 'date' })
    .generatedAlwaysAs(
      (): SQL =>
        sql`${Contracts.termEnd} - ${Contracts.noticePeriodInDays} * INTERVAL '1 day'`
    ),
  numberOfSeats: t.integer(),
  pricePerSeat: t.integer(),
  pricePerSeatDollars: t.integer().generatedAlwaysAs(
    (): SQL => sql`
        CASE
          WHEN (${Contracts.pricePerSeat} IS NOT NULL) THEN
            ROUND(${Contracts.pricePerSeat} / 100, 0)
          ELSE
            NULL
        END
      `
  ),
  savings: t.bigint({ mode: 'number' }),
  savingsNotes: t.text(),
  savingsDollars: t.bigint({ mode: 'number' }).generatedAlwaysAs(
    (): SQL => sql`
        CASE
          WHEN (${Contracts.savings} > 0) THEN
           ROUND(${Contracts.savings} / 100, 0)
          ELSE
            NULL
        END
      `
  ),
  annualizedSavingsDollars: t.bigint({ mode: 'number' }).generatedAlwaysAs(
    (): SQL => sql`
        CASE
            WHEN ${Contracts.savings} IS NOT NULL AND ${Contracts.type} = 'recurring' THEN ROUND((${Contracts.savings} * 12 / 100)::numeric, 0)
            WHEN ${Contracts.savings} IS NOT NULL AND ${Contracts.type} = 'contract' AND (${Contracts.termEnd} - ${Contracts.termStart}) > 0 THEN ROUND((${Contracts.savings} / (${Contracts.termEnd} - ${Contracts.termStart}))::numeric * 365.25 / 100::numeric, 0)
            ELSE NULL::numeric
        END
      `
  ),
  ...timestamps
}))

export type Contract = typeof Contracts.$inferSelect
export type ContractInsert = typeof Contracts.$inferInsert

export const contractRelations = relations(Contracts, ({ many, one }) => ({
  attachments: many(Attachments),
  department: one(Departments, {
    fields: [Contracts.departmentId],
    references: [Departments.id]
  }),
  organization: one(Organizations, {
    fields: [Contracts.orgId],
    references: [Organizations.id]
  }),
  owner: one(Users, {
    fields: [Contracts.ownerId],
    references: [Users.id]
  }),
  vendor: one(Vendors, {
    fields: [Contracts.vendorId],
    references: [Vendors.id]
  })
}))

const CurrencySchema = z.coerce
  .number()
  .int()
  .min(0)
  .transform((v) => v * 100)

export const insertContractSchema = createInsertSchema(Contracts, {
  status: ContractStatusEnum.optional(),
  noticePeriodInDays: z.coerce.number().int().min(0).optional(),
  value: CurrencySchema.optional(),
  savings: CurrencySchema.optional(),
  pricePerSeat: CurrencySchema.optional(),
  numberOfSeats: z.coerce.number().int().min(0).optional(),
  termStart: z.string().datetime({ offset: true }).optional(),
  termEnd: z.string().datetime({ offset: true }).optional()
})

export const selectContractSchema = createSelectSchema(Contracts)

export const ContractTypeSchema = selectContractSchema.shape.type

type ContractType = z.infer<typeof ContractTypeSchema>

export const ContractTypeCopy: Record<ContractType, string> = {
  recurring: 'Monthly Recurring',
  contract: 'Contract'
}

export type PaymentTerm = NonNullable<
  z.infer<typeof selectContractSchema>['paymentTerms']
>

export const PaymentTermsCopy: Record<PaymentTerm, string> = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  biannual: 'Biannual',
  annual: 'Annual',
  upfront: 'Upfront'
}

export type ContractStatus = z.infer<typeof ContractStatusEnum>

export const ContractStatusCopy: Record<ContractStatus, string> = {
  active: 'Active',
  expired: 'Inactive',
  pending_cancellation: 'Pending cancellation',
  pending_renewal: 'Pending renewal',
  pending_upgrade: 'Pending upgrade',
  under_review: 'Under review',
  future: 'Upcoming',
  draft: 'Draft'
}

export const ActiveContractStatusesEnum = ContractStatusEnum.extract([
  'active',
  'pending_renewal',
  'pending_upgrade',
  'pending_cancellation',
  'under_review'
])
