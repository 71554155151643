import { relations } from 'drizzle-orm'
import { foreignKey, pgTable, primaryKey } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Departments } from './departments'
import { Memberships } from './memberships'
import { Users } from './users'

export const UserDepartments = pgTable(
  'user_departments',
  (t) => ({
    userId: t.text().notNull(),
    orgId: t.text().notNull(),
    departmentId: t
      .text()
      .notNull()
      .references(() => Departments.id, { onDelete: 'cascade' })
  }),
  (t) => [
    primaryKey({ columns: [t.userId, t.orgId] }),
    foreignKey({
      columns: [t.userId, t.orgId],
      foreignColumns: [Memberships.userId, Memberships.orgId]
    }).onDelete('cascade')
  ]
)

export type UserDepartment = typeof UserDepartments.$inferSelect
export type UserDepartmentInsert = typeof UserDepartments.$inferInsert

export const userDepartmentRelations = relations(
  UserDepartments,
  ({ one }) => ({
    department: one(Departments, {
      fields: [UserDepartments.departmentId],
      references: [Departments.id]
    }),
    user: one(Users, {
      fields: [UserDepartments.userId],
      references: [Users.id]
    })
  })
)

export const insertUserDepartmentSchema = createInsertSchema(UserDepartments)
export const selectUserDepartmentSchema = createSelectSchema(UserDepartments)
