import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { Contracts } from './contracts'
import { timestamps } from './shared'
import { Users } from './users'

export const ContractComments = pgTable('contract_comments', (t) => ({
  id: t.uuid().primaryKey().defaultRandom(),
  contractId: t
    .text()
    .notNull()
    .references(() => Contracts.id, { onDelete: 'cascade' }),
  userId: t.text().references(() => Users.id, { onDelete: 'set null' }),
  text: t.text().notNull(),
  ...timestamps
}))

export type ContractComment = typeof ContractComments.$inferSelect
export type ContractCommentInsert = typeof ContractComments.$inferInsert

export const contractCommentRelations = relations(
  ContractComments,
  ({ one }) => ({
    contract: one(Contracts, {
      fields: [ContractComments.contractId],
      references: [Contracts.id]
    }),
    user: one(Users, {
      fields: [ContractComments.userId],
      references: [Users.id]
    })
  })
)

export const insertContractCommentSchema = createInsertSchema(ContractComments)
export const selectContractCommentSchema = createSelectSchema(ContractComments)
