import { pgEnum } from 'drizzle-orm/pg-core'
import { z } from 'zod'
import { workflowsSchema } from './schemas'

export const AuthProvidersEnum = z.enum(['google', 'microsoft'])

export const PaymentTermsEnum = pgEnum('payment_terms', [
  'monthly',
  'quarterly',
  'biannual',
  'annual',
  'upfront'
])

export const ContractStatusEnum = z.enum([
  'active',
  'expired',
  'pending_renewal',
  'pending_cancellation',
  'pending_upgrade',
  'under_review',
  'future',
  'draft'
])

export const ContractTypeEnum = pgEnum('contract_type', [
  'recurring',
  'contract'
])

export const UserRoleEnum = z.enum(['admin', 'restricted', 'viewer'])

export const ReminderMilestoneEnum = z.enum(['termEnd', 'noticeDeadline'])

export const intakeFormFlowEnum = workflowsSchema.enum('intake_form_flow', [
  'new_purchase',
  'contract'
])

export const contractActionEnum = workflowsSchema.enum('contract_action', [
  'renew',
  'upgrade',
  'cancel'
])
