import { relations, sql } from 'drizzle-orm'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'
import { contractActionEnum, intakeFormFlowEnum } from './enums'
import { workflowsSchema } from './schemas'
import { timestamps } from './shared'
import { Users } from './users'
import { generateId } from './utils'

export const IntakeForms = workflowsSchema.table('intake_forms', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  userId: t.text().references(() => Users.id, { onDelete: 'set null' }),
  flow: intakeFormFlowEnum().notNull(),
  contractAction: contractActionEnum(),
  data: t.jsonb().default({}).notNull(),
  submittedAt: t.timestamp({
    mode: 'date',
    withTimezone: true
  }),
  expiresAt: t
    .timestamp({
      mode: 'date',
      withTimezone: true
    })
    .notNull()
    .default(sql`NOW() + INTERVAL '1 hour'`),
  ...timestamps
}))

export type IntakeForm = typeof IntakeForms.$inferSelect
export type IntakeFormInsert = typeof IntakeForms.$inferInsert

export const intakeFormRelations = relations(IntakeForms, ({ one }) => ({
  user: one(Users, {
    fields: [IntakeForms.userId],
    references: [Users.id]
  })
}))

export const insertIntakeFormSchema = createInsertSchema(IntakeForms)
export const selectIntakeFormSchema = createSelectSchema(IntakeForms)

export const CancelContractFormSchema = z.object({
  reason: z.string().min(1, 'This field is required.'),
  timeline: z.enum(['immediate', 'contract_end', 'other']),
  notes: z.string().optional(),
  vendorContactEmail: z
    .string()
    .email('Please enter a valid email address.')
    .optional()
})

type CancellationTimeline = z.infer<
  typeof CancelContractFormSchema.shape.timeline
>

export const CancellationTimelineCopy: Record<CancellationTimeline, string> = {
  immediate: 'Immediate',
  contract_end: 'Contract End',
  other: 'Other'
}

export const TermLengthEnum = z.enum(['1_year', '2_years', '3_years', 'open'])

export const BillingFrequencyEnum = z.enum([
  'month',
  'quarter',
  'annual',
  'upfront',
  'open'
])

export const ChangeContractFormSchema = z.object({
  requestDetails: z.string().min(1, 'This field is required.'),
  amount: z.string().min(1, 'This field is required.'),
  reviewUtilization: z.enum(['on', 'off']).transform((v) => v === 'on'),
  termLength: TermLengthEnum,
  billingFrequency: BillingFrequencyEnum,
  expectedPurchaseDate: z.string().min(1, 'This field is required.'),
  budgetedCost: z.string().min(1, 'This field is required.'),
  vendorContactEmail: z
    .string()
    .email('Please enter a valid email address.')
    .optional()
})

export const ReviewContractFormSchema = z.object({
  requestDetails: z.string().min(1, 'This field is required.'),
  vendorContactEmail: z
    .string()
    .email('Please enter a valid email address.')
    .optional(),
  termLength: TermLengthEnum.optional(),
  billingFrequency: BillingFrequencyEnum.optional()
})

type BillingFrequency = z.infer<typeof BillingFrequencyEnum>

type TermLength = z.infer<typeof TermLengthEnum>

export const BillingFrequencyCopy: Record<BillingFrequency, string> = {
  month: 'Monthly',
  quarter: 'Quarterly',
  annual: 'Annually',
  upfront: 'Upfront',
  open: 'Open to all depending on savings'
}

export const TermLengthCopy: Record<TermLength, string> = {
  '1_year': '1 year',
  '2_years': '2 years',
  '3_years': '3 years',
  open: 'Open to all depending on savings'
}
